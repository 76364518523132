import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Generar from "./generar";
import TiendaDashBoard from "./tiendadashboard";
import Blog from "./blog";
import BlogPost from "./BlogPost";
import Consulta from "./consulta";
import Cursos from "./cursos";
import Descarga from "./descarga";
import TiendaHistoricos from "./historico_envios";
import Mensajeria from './mensajeria';
import Registros from "./registros";
import TiendaVentas from './TiendaVentas';
import Usuario from "./usuario";

//import Configuracion from "./configuracion";
import Salir from "./salir";
//import TiendaDashBoard from "./TiendaDashBoard";
//import { useNavigate } from "react-router-dom";//para salir 


const Tienda = () => {
  const [selectedItem, setSelectedItem] = useState('tiendadashboard');
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  //const navigate = useNavigate();//para sarir
  //const [desde, setDesde] = useState("");
  const [Name_Tienda, setName_Tienda] = useState(localStorage.getItem('name_tienda'));
  const [id_tienda, setId_tienda] = useState(localStorage.getItem('id_tienda')); // nombre usuario
  const [usuarioData, setUsuarioData] = useState(null); // Estado para almacenar los datos de la logística
  const [userNombre, setUserNombre] = useState(localStorage.getItem('userNombre'));
  
  const [userId, setUserId] = useState('');
  const [isFormVisible, setFormVisible] = useState(false); // Estado para controlar la visibilidad del formulario

  useEffect(() => {
    setUserId(localStorage.getItem('userId'));
    console.log('userId:' + userId);
  }, [userId]);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === 'salir') {
      window.location.href = 'https://www.shipmaster.com.ar';
    } else {
      setSelectedItem(item);
    }
  };

  const handleConfig = async (userId) => {
    setFormVisible(true); // Mostrar el formulario al hacer clic en el botón de configuración
    try {
      const response = await fetch('https://shipmaster.com.ar/backoffice/php/admindash/configUser.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ usuarioId: userId }),
      });

      const data = await response.json();
      setUsuarioData(data); // Guardar los datos en el estado
    } catch (error) {
      console.error('Error al obtener los datos del usuario:', error);
    }
  };
  const handleCancel = () => {
    setFormVisible(false); // Ocultar el formulario al cancelar
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUsuarioData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch('https://shipmaster.com.ar/backoffice/php/admindash/configUserUpdate.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(usuarioData),
      });

      if (response.ok) {
        alert('Usuario actualizado correctamente');
        setFormVisible(false); // Ocultar el formulario después de actualizar los datos
      } else {
        alert('Hubo un error al actualizar los datos');
      }
    } catch (error) {
      console.error('Error al actualizar usuario:', error);
    }
  };

  const menuItems = [
    { id: 'tiendadashboard', label: 'DashBoard', icon: 'fas fa-chart-bar', color: '#b87f05' },
    { id: 'generar', label: 'Nuevo Envio', icon: 'fas fa-chart-line', color: '#0c5da1' },
    { id: 'viajando', label: 'Entregados', icon: 'fas fa-database', color: '#6b6b6b' },
    { id: 'colecta', label: 'Colecta', icon: 'fas fa-database', color: '#6b6b6b' },
    { id: 'vendidos', label: 'Ventas', icon: 'fas fa-chart-line', color: '#0c5da1' },
    { id: 'historico', label: 'Historico de envios', icon: 'fas fa-leaf', color: 'green' },
    { id: 'mensajeria', label: 'Mensajeria', icon: 'fas fa-send', color: 'blue' },
    { id: 'salir', label: 'Salir', icon: 'fas fa-sign-out-alt', color: 'red' },
  ];
  /////// navbar de tienda
  return (
    <div className='dashboard'>
      <div className={`sidebar ${isSidebarVisible ? 'expanded' : ''}`}>
        <div>
          <img
            src={`https://shipmaster.com.ar/backoffice/imgTiendas/${Name_Tienda}.png`}
            alt={`Imagen de ${Name_Tienda}.png`}
            className='img-logistica'
          />
          <h4>Portal Tienda</h4>
          <h4>
            {Name_Tienda} - {id_tienda}
          </h4>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <button onClick={() => handleConfig(userId)} className='button-config'>
              <i className='fas fa-cog'></i>
            </button>{' '}
            User: {userNombre}
          </p>
        </div>
        <ul>
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.id}
              onClick={() => handleItemClick(menuItem.id)}
              className={selectedItem === menuItem.id ? 'active' : ''}
            >
              <i class={menuItem.icon} style={{ color: menuItem.color }}></i> {menuItem.label}
            </li>
          ))}
        </ul>
      </div>
      <div className='contentDash1'>
        <div className='contentDashConfig'>
          {isFormVisible && usuarioData && (
            <form className='logistica-formConfig'>
              <h2>Editar Usuario</h2>
              <label>
                Nombre :
                <input
                  type='text'
                  name='nombre_usuario'
                  value={usuarioData.nombre_usuario || ''}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                CUIT :
                <input
                  type='text'
                  name='cuit_usuario'
                  value={usuarioData.cuit_usuario || ''}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Mail:
                <input
                  type='email'
                  name='mail_usuario'
                  value={usuarioData.mail_usuario || ''}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Contacto:
                <input
                  type='text'
                  name='contacto_usuario'
                  value={usuarioData.contacto_usuario || ''}
                  onChange={handleInputChange}
                />
              </label>
              <button type='button' className='button-updateConfig' onClick={handleUpdate}>
                Actualizar
              </button>
              <button type='button' className='button-cancelConfig' onClick={handleCancel}>
                Cancelar
              </button>
            </form>
          )}
        </div>
      </div>
      <div className='contentDash'>
        <div className='toggle-container'>
          <div className={`toggle-button ${isSidebarVisible ? 'expanded' : ''}`} onClick={toggleSidebar}>
            <span className='arrow'></span>
          </div>
        </div>

        {/* Componentes del dashboard */}
        <div className={`content ${isSidebarVisible ? 'expanded' : ''}`}>
          {/* Renderizar el componente correspondiente según la selección del menú */}
          {selectedItem === 'tiendadashboard' && <TiendaDashBoard />}
          {selectedItem === 'generar' && <Generar />}
          {selectedItem === 'viajando' && <Registros />}
          {selectedItem === 'vendidos' && <TiendaVentas />}
          {selectedItem === 'historico' && <TiendaHistoricos />}
          {selectedItem === 'mensajeria' && <Mensajeria />}
          {selectedItem === 'consulta' && <Consulta />}
          {selectedItem === 'descarga' && <Descarga />}
          {selectedItem === 'usuario' && <Usuario />}
          {selectedItem === 'blog' && <Blog />}
          {selectedItem === '/:id' && <BlogPost />}
          {selectedItem === 'cursos' && <Cursos />}
          {/* {selectedItem === "configuracion" && <Configuracion />} */}
          {selectedItem === 'salir' && <Salir />}
        </div>
      </div>
    </div>
  );
};

export default Tienda;
