/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Logo from "./Image/logo/logoShipMaster.png";
import JsonData from "../data/data.json";

export const Header = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
  const [logoAppeared, setLogoAppeared] = useState(false);
  const [titleAppeared, setTitleAppeared] = useState(false);

  useEffect(() => {
    setLandingPageData(JsonData);
    // para el logo
    setTimeout(() => {
      setLogoAppeared(true);
    }, 500);
    // para las letras
    setTimeout(() => {
      setTitleAppeared(true);
    }, 500);
  }, [landingPageData]);

  return (
    <header id="header" className="text-center">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                {/*logo animado shipmaster
                <Logo
                  src={props.data ? props.data.logo : 'Cargando'}
                  className={`logoanimacion ${logoAppeared ? 'appear' : ''}`}
                />*/}
                <div style={{ width: "50%", textAlign: "center", margin:'auto' }}>
                  <img
                    src={Logo}
                    className="img-responsive"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      textAlign: "center",
                    }} // Ajusta el width de la imagen aquí
                  />
                </div>
                {/*letras shipmaster
                <h1
                  className={`title-fade-in ${titleAppeared ? "appear" : ""}`}
                >
                  {props.data ? props.data.title : "Cargando"}
                </h1>*/}
                <p
                  className={`title-fade-in ${titleAppeared ? "appear" : ""}`}
                  style={{ marginTop: "15px" }}
                >
                  <font size="7">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: props.data ? props.data.paragraph : "Cargando",
                      }}
                    />
                  </font>
                </p>
                {/*LINEA*/}
                <div className="line"></div>

                <p />
                <div style={{ width: "97%" }}>
                  <p
                    style={{ fontFamily: "Arial Black", textAlign: "justify" }}
                  >
                    <font size="6">
                      {props.data && props.data.landing ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.data.landing,
                          }}
                        />
                      ) : (
                        "Cargando..."
                      )}
                    </font>
                  </p>
                </div>
                <p>
                  <a href="#about" className="btn btn-custom btn-lg">
                    <span style={{ fontSize: "24px" }}>+</span> Info
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
