import React, { useEffect, useState } from "react";
import "./administrarlogisticas.css";

const AdministrarLogisticas = () => {
    const [userId, setUserId] = useState("");
    const [logisticass, setlogisticass] = useState([]);
    const [jerarquiaOptions, setJerarquiaOptions] = useState([]);
    const [rolOptions, setRolOptions] = useState([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showNewLogisticaForm, setShowNuevalogistica] = useState(false);
    const [editlogisticas, setEditlogisticas] = useState(null); // Almacena los datos de la logisticas seleccionada para editar
    const [usuarioslogisticas, setUsuarioslogisticas] = useState([]); // Almacena los usuarios de la logisticas seleccionada
    const [showUsuariosForm, setShowUsuariosForm] = useState(false); // Mostrar el formulario de usuarios
    const [editUsuario, setEditUsuario] = useState(null); // Almacena los datos de la logisticas seleccionada para editar
    const [editMode, setEditMode] = useState(false); // Modo de edición para los campos de usuario

    const [NuevaLogistica, setNuevaLogistica] = useState({
        id_jerarquia: '',
        nombre_logistica: '',
        cuit_logistica: '',
        mail_logistica: '',
        contacto_logistica: '',
        telefono_logistica: '',
        direccion_logistica: '',
        user1: '',
        pass_user1: '',
        nombre_user1: '',
        mail_user1: '',
        jerarquia_user1: '',
        rol_user1: '',

        user2: '',
        pass_user2: '',
        nombre_user2: '',
        mail_user2: '',
        jerarquia_user2: '',
        rol_user2: '',

        // Agrega más campos según sea necesario
    });

    useEffect(() => {
        // Obtenemos el userId del localStorage
        const idlogist = localStorage.getItem("userId");
        console.log("ID del usuario:", idlogist);
        setUserId(idlogist);

        // Realizar la solicitud GET al servidor PHP con el userId como parámetro
        fetch(`https://shipmaster.com.ar/backoffice/php/admindash/listarlogisticas.php`)
            .then(response => response.json())
            .then(data => {
                // Actualizar el estado de logisticass con los datos recibidos
                setlogisticass(data);
            })
            .catch(error => console.error("Error al obtener las logisticass:", error));


        fetch('https://shipmaster.com.ar/backoffice/php/admindash/jerarquias.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al obtener los datos de jerarquia');
                }
                return response.json();
            })
            .then(data => {
                // Actualiza el estado jerarquiaOptions con los datos obtenidos del servidor
                setJerarquiaOptions(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });


        fetch('https://shipmaster.com.ar/backoffice/php/admindash/rol.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al obtener los datos de rol');
                }
                return response.json();
            })
            .then(data => {
                // Actualiza el estado jerarquiaOptions con los datos obtenidos del servidor
                setRolOptions(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });






    }, []);

    const openEditForm = (logisticas) => {
        setEditlogisticas(logisticas);
        setShowEditForm(true);
    };


    const openNewLogisticaForm = () => {
        // Inicializar los campos en blanco
        setNuevaLogistica({
            id_jerarquia: '',
            nombre_logistica: '',
            cuit_logistica: '',
            mail_logistica: '',
            contacto_logistica: '',
            telefono_logistica: '',
            direccion_logistica: '',
            user1: '',
            pass_user1: '',
            nombre_user1: '',
            mail_user1: '',
            jerarquia_user1: '',
            rol_user1: '',

            user2: '',
            pass_user2: '',
            nombre_user2: '',
            mail_user2: '',
            jerarquia_user2: '',
            rol_user2: '',

        });
        // Abrir el formulario
        setShowNuevalogistica(true);
    };








    const closeEditForm = () => {
        setShowEditForm(false);
        setEditlogisticas(null);

    };

    const closeNLForm = () => {
        setShowNuevalogistica(false);
        setShowNuevalogistica(null);
    };

    const closeUsuariosForm = () => {
        setShowUsuariosForm(false);
        setEditUsuario(null);
    };

    const handleSave = () => {
        console.log("Datos de editlogisticas:", editlogisticas); // Imprime los datos de editlogisticas por consola

        // Realiza una solicitud POST al servidor PHP para actualizar la logisticas
        fetch('https://shipmaster.com.ar/backoffice/php/listarlogisticassactualizar.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editlogisticas), // Envía los datos de editlogisticas al servidor
        })
            .then(response => {
                if (response.ok) {
                    // Si la solicitud fue exitosa, cierra el formulario de edición
                    closeEditForm();
                    // Actualiza la lista de logisticass haciendo una nueva solicitud GET
                    fetchlogisticass(); // Llama a la función fetchlogisticass para obtener las logisticass actualizadas
                } else {
                    // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                    console.error('Error al actualizar la logisticas');
                }
            })
            .catch(error => {
                console.error('Error al enviar la solicitud:', error);
            });
    };

    const handleSaveNL = () => {
        console.log("Datos de nueva logistica y usuarios:", NuevaLogistica); // Imprime los datos de editlogisticas por consola

        // Realiza una solicitud POST al servidor PHP para actualizar la logisticas
        fetch('https://shipmaster.com.ar/backoffice/php/admindash/savenl.php', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(NuevaLogistica), // Envía los datos de editlogisticas al servidor
        })
            .then(response => {
                if (response.ok) {
                    // Si la solicitud fue exitosa, cierra el formulario de edición
                    closeNLForm();
                    // Actualiza la lista de logisticass haciendo una nueva solicitud GET
                    fetchlogisticass(); // Llama a la función fetchlogisticass para obtener las logisticass actualizadas
                } else {
                    // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                    console.error('Error al Agregar nueva logistica');
                }
            })
            .catch(error => {
                console.error('Error al enviar la solicitud:', error);
            });
    };


    const handleCancel = () => {
        // Simplemente cierra el formulario de edición sin hacer ningún cambio
        closeEditForm();
    };
    const handleCancelNL = () => {
        // Simplemente cierra el formulario de edición sin hacer ningún cambio
        closeNLForm();
    };

    const handleCancelUsers = () => {
        // Simplemente cierra el formulario de edición sin hacer ningún cambio
        //setEditMode({ ...editMode, [index]: false });
        if (editMode === true) { setEditMode({ ...editMode, 0: false }); }
        closeUsuariosForm();
    };




    const handleDelete = (idlogisticas) => {
        // Mostrar una ventana de confirmación antes de eliminar el registro
        if (window.confirm("¿Estás seguro de que deseas eliminar esta logisticas?")) {
            // Realiza una solicitud POST al servidor PHP para eliminar la logisticas
            fetch('https://shipmaster.com.ar/backoffice/php/listarlogisticaeliminar.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_logisticas: idlogisticas }), // Envía el ID de la logisticas a eliminar
            })
                .then(response => {
                    if (response.ok) {
                        // Si la solicitud fue exitosa, actualiza la lista de logisticass
                        fetchlogisticass(); // Llama a la función fetchlogisticass para obtener las logisticass actualizadas
                    } else {
                        // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                        console.error('Error al eliminar la logisticas');
                    }
                })
                .catch(error => {
                    console.error('Error al enviar la solicitud:', error);
                });
        }
    };

    const handleEdit = (index) => {
        // Cambiar al modo de edición para el usuario en la posición de índice
        setEditMode({ ...editMode, [index]: true });
    };

    const handleCancelEdit = (index) => {
        // Cancelar la edición y volver al modo de visualización para el usuario en la posición de índice
        setEditMode({ ...editMode, [index]: false });
    };

    const fetchUsuarioslogisticas = (idlogisticas) => {
        // Realizar una solicitud GET al servidor para obtener los usuarios de la logisticas
        fetch(`https://shipmaster.com.ar/backoffice/php/listarlogisticassusuarios.php?idlogisticas=${idlogisticas}`)
            .then(response => response.json())
            .then(data => {
                // Actualizar el estado de usuarioslogisticas con los datos recibidos
                setUsuarioslogisticas(data);
                // Mostrar el formulario de usuarios de la logisticas
                setShowUsuariosForm(true);
            })
            .catch(error => console.error("Error al obtener los usuarios de la logisticas:", error));
    };

    const handleInputChange = (e, index) => {
        // Actualizar el usuario en la posición de índice con el nuevo valor
        const updatedUsuarios = [...usuarioslogisticas];
        updatedUsuarios[index].usuario = e.target.value;
        setUsuarioslogisticas(updatedUsuarios);
    };

    const handleSaveEdit = (index) => {
        // Realiza una solicitud POST al servidor PHP para actualizar el usuario
        fetch('https://shipmaster.com.ar/backoffice/php/listarlogisticasseditarusuario.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(usuarioslogisticas[index]), // Envía los datos del usuario editado al servidor
        })
            .then(response => {
                if (response.ok) {
                    // Si la solicitud fue exitosa, cancela el modo de edición
                    setEditMode({ ...editMode, [index]: false });
                    // Actualiza la lista de usuarios haciendo una nueva solicitud GET
                    fetchUsuarioslogisticas(editlogisticas.id_logisticas); // Llama a la función fetchUsuarioslogisticas para obtener los usuarios actualizados
                } else {
                    // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                    console.error('Error al actualizar el usuario');
                }
            })
            .catch(error => {
                console.error('Error al enviar la solicitud:', error);
            });
    };


    const fetchlogisticass = () => {
        // Realizar una solicitud GET al servidor para obtener las logisticass actualizadas
        fetch(`https://shipmaster.com.ar/backoffice/php/admindash/listarusuarios.php?userId=${userId}`)
            .then(response => response.json())
            .then(data => {
                // Actualizar el estado de logisticass con los datos recibidos
                setlogisticass(data);
            })
            .catch(error => console.error("Error al obtener las logisticass:", error));
    };

    return (
        <div className="containercursos">
            <h4>
                Administrar Logisticas{" "}
                <i
                    className="fas fa-graduation-cap"
                    style={{ paddingLeft: "20px", color: "#0c5da1" }}
                ></i>
            </h4>
            <h4>{userId}</h4>
            <button onClick={openNewLogisticaForm}>Nueva Logistica</button>
            <br /><br />
            {/* Mostrar las logisticass en una tabla */}
            <table>
                <thead>
                    <tr>
                        {/** `logisticas`(`id_logistica`, `id_jerarquia`, `nombre_logistica`, `cuit_logistica`, `mail_logistica`, `contacto_logistica`,
                         *  `telefono_logistica`, `direccion_logistica`, `fechayhora`)  */}
                        <th>id_logistica</th>
                        <th>id_jerarquia</th>
                        <th>nombre_logistica</th>
                        <th>cuit_logistica</th>
                        <th>mail_logistica</th>
                        <th>contacto_logistica</th>
                        <th>telefono_logistica</th>
                        <th>direccion_logistica</th>
                        <th>fechayhora</th>
                        <th>O   pciones</th>
                    </tr>
                </thead>
                <tbody>
                    {logisticass.map(logisticas => (
                        <tr key={logisticas.id_logistica}>
                            <td>{logisticas.id_logistica}</td>
                            <td>{logisticas.id_jerarquia}</td>
                            <td>{logisticas.nombre_logistica}</td>
                            <td>{logisticas.cuit_logistica}</td>
                            <td>{logisticas.mail_logistica}</td>
                            <td>{logisticas.contacto_logistica}</td>
                            <td>{logisticas.telefono_logistica}</td>
                            <td>{logisticas.direccion_logistica}</td>
                            <td>{logisticas.fechayhora}</td>
                            <td>
                                <button onClick={() => openEditForm(logisticas)}>Editar</button>
                                <button onClick={() => handleDelete(logisticas.id_usuario)}>Eliminar</button> {/* boton eliminar */}
                                <button onClick={() => fetchUsuarioslogisticas(logisticas.id_usuario)}>Usuarios</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


            {/* Mostrar el formulario de ALTA de logistica si showNewLogisticaForm es true */}
            {showNewLogisticaForm && (
                <div className="modal-container">
                    <div className="modal-content">
                        <h3>Nueva Logistica</h3>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '50%', textAlign: "center" }}>Datos de la logistica</th>
                                    <th colSpan="2" style={{ width: '50%', textAlign: "center" }}>Usuarios</th>
                                </tr>
                                <tr>
                                    <th style={{ width: '50%', textAlign: "center" }}></th>
                                    <th style={{ width: '25%', textAlign: "center" }}>Usuario Logistica</th>
                                    <th style={{ width: '25%', textAlign: "center" }}>Usuario Administrativo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>





                                        <form>
                                            <label htmlFor="Jerarquia">Jerarquia:</label>
                                            <select
                                                id="id_jerarquia"
                                                name="id_jerarquia"
                                                value={NuevaLogistica.id_jerarquia}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, id_jerarquia: e.target.value })}
                                            >
                                                {jerarquiaOptions.map(option => (
                                                    <option key={option.id_jerarquia} value={option.id_jerarquia}>{option.id_jerarquia}-{option.nombre_jerarquia}</option>
                                                ))}
                                            </select>


                                            <p></p>
                                            <label htmlFor="nombre_logistica">Nombre Logística:</label>
                                            <input
                                                type="text"
                                                id="nombre_logistica"
                                                name="nombre_logistica"
                                                value={NuevaLogistica.nombre_logistica}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, nombre_logistica: e.target.value })}
                                            />

                                            <label htmlFor="cuit_logistica">CUIT Logística:</label>
                                            <input
                                                type="text"
                                                id="cuit_logistica"
                                                name="cuit_logistica"
                                                value={NuevaLogistica.cuit_logistica}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, cuit_logistica: e.target.value })}
                                            />

                                            <label htmlFor="mail_logistica">Mail Logística:</label>
                                            <input
                                                type="text"
                                                id="mail_logistica"
                                                name="mail_logistica"
                                                value={NuevaLogistica.mail_logistica}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, mail_logistica: e.target.value })}
                                            />

                                            <label htmlFor="contacto_logistica">Contacto Logística:</label>
                                            <input
                                                type="text"
                                                id="contacto_logistica"
                                                name="contacto_logistica"
                                                value={NuevaLogistica.contacto_logistica}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, contacto_logistica: e.target.value })}
                                            />

                                            <label htmlFor="telefono_logistica">Teléfono Logística:</label>
                                            <input
                                                type="text"
                                                id="telefono_logistica"
                                                name="telefono_logistica"
                                                value={NuevaLogistica.telefono_logistica}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, telefono_logistica: e.target.value })}
                                            />

                                            <label htmlFor="direccion_logistica">Dirección Logística:</label>
                                            <input
                                                type="text"
                                                id="direccion_logistica"
                                                name="direccion_logistica"
                                                value={NuevaLogistica.direccion_logistica}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, direccion_logistica: e.target.value })}
                                            />

                                        </form>

                                    </td>
                                    <td>
                                        <form>
                                            <h5 >Usuario Administrativo 1:</h5>
                                            <label htmlFor="nombre_user1">Nombre y Apellido:</label>
                                            <input
                                                type="text"
                                                id="nombre_user1"
                                                name="nombre_user1"
                                                value={NuevaLogistica.nombre_user1}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, nombre_user1: e.target.value })}
                                            />

                                            <label htmlFor="user1">Usuario:</label>
                                            <input
                                                type="text"
                                                id="user1"
                                                name="user1"
                                                value={NuevaLogistica.user1}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, user1: e.target.value })}
                                            />
                                            <label htmlFor="user1">Pass:</label>
                                            <input
                                                type="text"
                                                id="pass_user1"
                                                name="pass_user1"
                                                value={NuevaLogistica.pass_user1}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, pass_user1: e.target.value })}
                                            />
                                            <br />

                                            <label htmlFor="mail_user1">Mail Usuario:</label>
                                            <input
                                                type="text"
                                                id="mail_user1"
                                                name="mail_user1"
                                                value={NuevaLogistica.mail_user1}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, mail_user1: e.target.value })}
                                            />

                                            <label htmlFor="jerarquia_user1">Jerarquía:</label>
                                            <select
                                                id="jerarquia_user1"
                                                name="jerarquia_user1"
                                                value={NuevaLogistica.jerarquia_user1}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, jerarquia_user1: e.target.value })}
                                            >
                                                {jerarquiaOptions.map(option => (
                                                    <option key={option.id_jerarquia} value={option.id_jerarquia}>{option.nombre_jerarquia}</option>
                                                ))}
                                            </select>

                                            <br /><br />
                                            <label htmlFor="rol_user1">rol:</label>
                                            <select
                                                id="rol_user1"
                                                name="rol_user1"
                                                value={NuevaLogistica.rol_user1}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, rol_user1: e.target.value })}
                                            >
                                                {rolOptions.map(option => (
                                                    <option key={option.id_rol} value={option.id_rol}>{option.nombre_rol}</option>
                                                ))}
                                            </select>


                                            <br />
                                            {/* Agrega más campos según necesites */}
                                        </form>
                                    </td>
                                    <td>
                                        <form>
                                            <h5 >Usuario Administrativo 2:</h5>
                                            <label htmlFor="nombre_user2">Nombre y Apellido:</label>
                                            <input
                                                type="text"
                                                id="nombre_user2"
                                                name="nombre_user2"
                                                value={NuevaLogistica.nombre_user2}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, nombre_user2: e.target.value })}
                                            />

                                            <label htmlFor="user2">Usuario:</label>
                                            <input
                                                type="text"
                                                id="user2"
                                                name="user2"
                                                value={NuevaLogistica.user2}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, user2: e.target.value })}
                                            />
                                            <label htmlFor="pass_user2">Pass:</label>
                                            <input
                                                type="text"
                                                id="pass_user2"
                                                name="pass_user2"
                                                value={NuevaLogistica.pass_user2}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, pass_user2: e.target.value })}
                                            />
                                            <br />

                                            <label htmlFor="mail_user2">Mail Usuario:</label>
                                            <input
                                                type="text"
                                                id="mail_user2"
                                                name="mail_user2"
                                                value={NuevaLogistica.mail_user2}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, mail_user2: e.target.value })}
                                            />

                                            <label htmlFor="jerarquia_user2">Jerarquía:</label>
                                            <select
                                                id="jerarquia_user2"
                                                name="jerarquia_user2"
                                                value={NuevaLogistica.jerarquia_user2}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, jerarquia_user2: e.target.value })}
                                            >
                                                {jerarquiaOptions.map(option => (
                                                    <option key={option.id_jerarquia} value={option.id_jerarquia}>{option.nombre_jerarquia}</option>
                                                ))}
                                            </select>

                                            <br /><br />
                                            <label htmlFor="rol_user2">rol:</label>
                                            <select
                                                id="rol_user2"
                                                name="rol_user2"
                                                value={NuevaLogistica.rol_user2}
                                                onChange={(e) => setNuevaLogistica({ ...NuevaLogistica, rol_user2: e.target.value })}
                                            >
                                                {rolOptions.map(option => (
                                                    <option key={option.id_rol} value={option.id_rol}>{option.nombre_rol}</option>
                                                ))}
                                            </select>
                                            <br />
                                            {/* Agrega más campos según necesites */}
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                        <button onClick={handleSaveNL}>Guardar</button>
                        <button onClick={handleCancelNL}>Cancelar</button>
                        <p style={{ marginBottom: '30px' }} />
                    </div>
                </div>
            )}




            {/* Mostrar el formulario de edición si showEditForm es true */}
            {showEditForm && (
                <div className="modal-container">
                    <div className="modal-content"> 
                        <h3>Editar Logistica</h3>
                        <form style={{ marginLeft: '50px', marginRight: '50px', marginBottom: '20px' }}>
                            {/** `logisticas`(`id_logistica`, `id_jerarquia`, `nombre_logistica`, `cuit_logistica`, `mail_logistica`, `contacto_logistica`,
                         *  `telefono_logistica`, `direccion_logistica`, `fechayhora`)  */}
                            <label htmlFor="id_logistica">id_logistica:</label>
                            <input type="text" id="id_logistica" name="id_logistica" value={editlogisticas.id_logistica} onChange={(e) => setEditlogisticas({ ...editlogisticas, id_logistica: e.target.value })} />

                            <label htmlFor="id_jerarquia">id_jerarquia:</label>
                            <input type="text" id="id_jerarquia" name="id_jerarquia" value={editlogisticas.id_jerarquia} onChange={(e) => setEditlogisticas({ ...editlogisticas, id_jerarquia: e.target.value })} />

                            <label htmlFor="nombre_logistica">nombre_logistica:</label>
                            <input type="text" id="nombre_logistica" name="nombre_logistica" value={editlogisticas.nombre_logistica} onChange={(e) => setEditlogisticas({ ...editlogisticas, nombre_logistica: e.target.value })} />

                            <label htmlFor="cuit_logistica">cuit_logistica:</label>
                            <input type="text" id="cuit_logistica" name="cuit_logistica" value={editlogisticas.cuit_logistica} onChange={(e) => setEditlogisticas({ ...editlogisticas, cuit_logistica: e.target.value })} />

                            <label htmlFor="mail_logistica">mail_logistica:</label>
                            <input type="text" id="mail_logistica" name="mail_logistica" value={editlogisticas.mail_logistica} onChange={(e) => setEditlogisticas({ ...editlogisticas, mail_logistica: e.target.value })} />

                            <label htmlFor="contacto_logistica">contacto_logistica:</label>
                            <input type="text" id="contacto_logistica" name="contacto_logistica" value={editlogisticas.contacto_logistica} onChange={(e) => setEditlogisticas({ ...editlogisticas, contacto_logistica: e.target.value })} />

                            <label htmlFor="telefono_logistica">telefono_logistica:</label>
                            <input type="text" id="telefono_logistica" name="telefono_logistica" value={editlogisticas.telefono_logistica} onChange={(e) => setEditlogisticas({ ...editlogisticas, telefono_logistica: e.target.value })} />

                            <label htmlFor="direccion_logistica">direccion_logistica:</label>
                            <input type="text" id="direccion_logistica" name="direccion_logistica" value={editlogisticas.direccion_logistica} onChange={(e) => setEditlogisticas({ ...editlogisticas, direccion_logistica: e.target.value })} />

                            <label htmlFor="fechayhora">fechayhora:</label>
                            <input type="text" id="fechayhora" name="fechayhora" value={editlogisticas.fechayhora} onChange={(e) => setEditlogisticas({ ...editlogisticas, fechayhora: e.target.value })} />

                            {/*<input type="text" id="contacto_logistica" name="contacto_logistica" value={editlogisticas.contacto_logisticas} onChange={(e) => setEditlogisticas({ ...editlogisticas, contacto_logisticas: e.target.value })} />
                            <label htmlFor="fechayhora">contacto_logistica:</label>*/}


                            {/* Agrega más campos según necesites */}
                        </form>
                        <button onClick={handleSave}>Guardar</button>
                        <button onClick={handleCancel}>Cancelar</button>
                        <p style={{ marginBottom: '30px' }} />
                    </div>
                </div>
            )}


            {/* Mostrar el formulario de usuarios de la logisticas si showUsuariosForm es true */}
            {/* Mostrar el formulario de usuarios de la logisticas si showUsuariosForm es true */}
            {showUsuariosForm && (
                <div className="modal-container">
                    <div className="modal-content">
                        <h3>Usuarios de la logisticas</h3>
                        {/* Tabla para mostrar los usuarios de la logisticas */}
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Usuario</th>
                                    <th>Password</th>
                                    <th>Mail</th>
                                    <th>Jerarquía</th>
                                    <th>Rol</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usuarioslogisticas.map((usuario, index) => (
                                    <tr key={usuario.id_usuario}>
                                        <td>{usuario.id_usuario}</td>
                                        <td>
                                            {/* Si está en modo de edición, muestra un campo de entrada, de lo contrario, solo muestra el valor */}
                                            {editMode[index] ? (
                                                <input
                                                    type="text"
                                                    value={usuario.usuario}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            ) : (
                                                usuario.usuario
                                            )}
                                        </td>
                                        <td>
                                            {editMode[index] ? (
                                                <input
                                                    type="text"
                                                    value={usuario.pass}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            ) : (
                                                usuario.pass
                                            )}
                                        </td>
                                        <td>
                                            {editMode[index] ? (
                                                <input
                                                    type="text"
                                                    value={usuario.mail_usuario}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            ) : (
                                                usuario.mail_usuario
                                            )}
                                        </td>

                                        <td>{usuario.jerarquia}</td>
                                        <td>{usuario.rol}</td>
                                        <td>
                                            {/* Mostrar el botón "Guardar" si está en modo de edición, de lo contrario, muestra "Editar" */}
                                            {editMode[index] ? (
                                                <button onClick={() => handleSaveEdit(index)}>Guardar</button>
                                            ) : (
                                                <button onClick={() => handleEdit(index)}>Editar</button>
                                            )}
                                            {/* Mostrar el botón "Cancelar" si está en modo de edición, de lo contrario, muestra "Eliminar" */}
                                            {editMode[index] && (
                                                <button onClick={() => handleCancelEdit(index)}>Cancelar</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <br />
                        <button onClick={handleCancelUsers} style={{ marginBottom: '20px' }}>Cancelar</button>
                        <br />
                    </div>
                    <br />
                </div>
            )}
            <div className="footer">
                <p>shipmaster - Tecnología en shipmaster</p>
            </div>
        </div>
    );
};

export default AdministrarLogisticas;
