import React, { useState, useEffect } from "react";

const AdministrarTiendas = () => {
    const [userId, setUserId] = useState("");
    const [Idlogis, setIdlogis] = useState("");
    const [Idtien, setIdtien] = useState("");

    const [tiendas, setTiendas] = useState([]);
    const [showNewTiendaForm, setShowNuevoTienda] = useState(false);
    const [NuevaTienda, setNuevaTienda] = useState({
        nombre_tienda: '',
        direccion_tienda: '',
        telefono_tienda: '',
        contacto_tienda: '',
        mail_tienda: '',
        cuit_tienda: '',
        Idlogis:Idlogis,
    });

    useEffect(() => {
        // Obtenemos el userId del localStorage
        setUserId(localStorage.getItem("userId"));
        console.log("userId en administrartiendas:" + userId)

        setIdlogis(localStorage.getItem("id_logis"));
        console.log("Idlogis:" + Idlogis)
        setIdtien(localStorage.getItem("id_tien"));
        console.log("Idtien:" + Idtien)

        // Realizar la solicitud GET al servidor PHP con el userId como parámetro
        fetch(`https://shipmaster.com.ar/backoffice/php/listartiendasadmin.php`)
            .then(response => response.json())
            .then(data => {
                // Actualizar el estado de tiendas con los datos recibidos
                setTiendas(data);
            })
            .catch(error => console.error("Error al obtener las tiendas:", error));
    }, [userId, Idlogis, Idtien]);

    const openNewTiendaForm = () => {
        // Restablecer el estado NuevaTienda a un objeto vacío
        setNuevaTienda({
            nombre_tienda: '',
            direccion_tienda: '',
            telefono_tienda: '',
            contacto_tienda: '',
            mail_tienda: '',
            cuit_tienda: '',
            Idlogis:Idlogis,
        });
        setShowNuevoTienda(true);
    };

    const handleSaveTienda = () => {
        console.log("Datos de nueva tienda:", NuevaTienda); // Imprime los datos de la nueva tienda por consola
        // Realiza una solicitud POST al servidor PHP para guardar la nueva tienda
        fetch('https://shipmaster.com.ar/backoffice/php/savetienda.php', {
            method: 'POST',
            body: JSON.stringify(NuevaTienda),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    // Si la solicitud fue exitosa, cierra el formulario de nueva tienda
                    closeNTForm();
                } else {
                    // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                    console.error('Error al agregar nueva tienda');
                }
            })
            .catch(error => {
                console.error('Error al enviar la solicitud:', error);
            });
    };

    const handleCancelNT = () => {
        // Simplemente cierra el formulario de nueva tienda sin hacer ningún cambio
        closeNTForm();
    };

    const closeNTForm = () => {
        setShowNuevoTienda(false);
    };

    return (
        <div className="containercursos">
            <h4>
                Administrar Tiendas {" "}
                <i
                    className="fas fa-graduation-cap"
                    style={{ paddingLeft: "20px", color: "#0c5da1" }}
                ></i>
            </h4>
            <h4>user id:{userId}</h4>
            <h4>id_logistica: {Idlogis}</h4>
            <h4>id_Tienda: {Idtien}</h4>
            <button onClick={openNewTiendaForm}>Nueva Tienda</button>
            {/* Mostrar las tiendas en una tabla */}
            <table>
                <thead>
                    <tr>
                        {/*`tienda`(`id_tienda`, `id_logistica`, `id_usuario`, `nombre_tienda`, `direccion_tienda`, `mail_tienda`, `telefono_tienda`, `cuit_tienda`, `contacto_tienda`, `activo`, `fechayhora`) */}
                        <th>ID Tienda</th>
                        <th>Nombre</th>
                        <th>Dirección</th>
                        <th>Teléfono</th>
                        <th>Contacto</th>
                        <th>Correo</th>
                        <th>CUIT</th>
                        <th>Activo</th>
                        <th>Fecha y Hora</th>
                    </tr>
                </thead>
                <tbody>
                    {tiendas.map(tienda => (
                        <tr key={tienda.id_tienda}>
                            <td>{tienda.id_tienda}</td>
                            <td>{tienda.nombre_tienda}</td>
                            <td>{tienda.direccion_tienda}</td>
                            <td>{tienda.telefono_tienda}</td>
                            <td>{tienda.contacto_tienda}</td>
                            <td>{tienda.mail_tienda}</td>
                            <td>{tienda.cuit_tienda}</td>
                            <td>{tienda.activo}</td>
                            <td>{tienda.fechayhora}</td>
                            {/* Agrega más campos según tus necesidades */}
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Mostrar el formulario de ALTA de tienda si showNewTiendaForm es true */}
            {showNewTiendaForm && (
                <div className="modal-container">
                    <div className="modal-content">
                        <h3>Nueva Tienda</h3>
                        <form>
                            <label htmlFor="nombre_tienda">Nombre:</label>
                            <input
                                type="text"
                                id="nombre_tienda"
                                name="nombre_tienda"
                                value={NuevaTienda.nombre_tienda}
                                onChange={(e) => setNuevaTienda({ ...NuevaTienda, nombre_tienda: e.target.value })}
                            />

                            <label htmlFor="direccion_tienda">Dirección:</label>
                            <input
                                type="text"
                                id="direccion_tienda"
                                name="direccion_tienda"
                                value={NuevaTienda.direccion_tienda}
                                onChange={(e) => setNuevaTienda({ ...NuevaTienda, direccion_tienda: e.target.value })}
                            />

                            <label htmlFor="telefono_tienda">Teléfono:</label>
                            <input
                                type="text"
                                id="telefono_tienda"
                                name="telefono_tienda"
                                value={NuevaTienda.telefono_tienda}
                                onChange={(e) => setNuevaTienda({ ...NuevaTienda, telefono_tienda: e.target.value })}
                            />

                            <label htmlFor="contacto_tienda">Contacto:</label>
                            <input
                                type="text"
                                id="contacto_tienda"
                                name="contacto_tienda"
                                value={NuevaTienda.contacto_tienda}
                                onChange={(e) => setNuevaTienda({ ...NuevaTienda, contacto_tienda: e.target.value })}
                            />

                            <label htmlFor="mail_tienda">Correo:</label>
                            <input
                                type="text"
                                id="mail_tienda"
                                name="mail_tienda"
                                value={NuevaTienda.mail_tienda}
                                onChange={(e) => setNuevaTienda({ ...NuevaTienda, mail_tienda: e.target.value })}
                            />

                            <label htmlFor="cuit_tienda">CUIT:</label>
                            <input
                                type="text"
                                id="cuit_tienda"
                                name="cuit_tienda"
                                value={NuevaTienda.cuit_tienda}
                                onChange={(e) => setNuevaTienda({ ...NuevaTienda, cuit_tienda: e.target.value })}
                            />
                        </form>
                        <button onClick={handleSaveTienda}>Guardar</button>
                        <button onClick={handleCancelNT}>Cancelar</button>
                        <p style={{ marginBottom: '30px' }} />
                    </div>
                </div>
            )}
            <div className="footer">
                <p>shipmaster - Tecnología en shipmaster</p>
            </div>
        </div>
    );
};

export default AdministrarTiendas;
