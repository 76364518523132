import React, { useState, useEffect } from "react";
import "./universopaquetes.css";

const UniversoPaquetes = () => {
  const [userId, setUserId] = useState("");
  const [Idlogis, setIdlogis] = useState("");
  const [Idtien, setIdtien] = useState("");

  const [universo, setUniverso] = useState([]);
  const [showNewTiendaForm, setShowNuevoTienda] = useState(false);
  const [NuevoPaquete, setNuevoPaquete] = useState({
    id_generar: "",
    id_logistica: "",
    id_local: "",
    factura: "",
    descripcion: "",
    cantidad: "",
    valorTotal: "",
    direccion: "",
    altura: "",
    piso: "",
    departamento: "",
    comuna: "",
    barrio: "",
    localidad: "",
    cp: "",
    telefono: "",
    nombreContacto: "",
    mailContacto: "",
    observaciones: "",
    domicilioTipo: "",
    horarioEntrega: "",
    hashCode: "",
    hashCtrl: "",
    pin: "",
    id_chofer: "",
    estado: "",
    fechacarga: "",
    fechaentrega: "",
    fechayhora: "",
  });
  const [editMode, setEditMode] = useState(false); // Modo de edición para los campos de usuario

  useEffect(() => {
    // Obtenemos el userId del localStorage
    setUserId(localStorage.getItem("userId"));
    console.log("userId en universo paquetes:" + userId);

    setIdlogis(localStorage.getItem("id_logis"));
    console.log("Idlogis:" + Idlogis);
    setIdtien(localStorage.getItem("id_tien"));
    console.log("Iduniverso paquetes:" + Idtien);

    // Realizar la solicitud GET al servidor PHP con el userId como parámetro
    fetch(
      `https://shipmaster.com.ar/backoffice/php/admindash/listaruniversopaquetesadmin.php`
    )
      .then((response) => response.json())
      .then((data) => {
        // Actualizar el estado de tiendas con los datos recibidos
        setUniverso(data);
      })
      .catch((error) =>
        console.error("Error al obtener el universo de paquetes:", error)
      );
  }, [userId, Idlogis, Idtien]);

  const openNewTiendaForm = () => {
    // Restablecer el estado NuevaTienda a un objeto vacío
    setNuevoPaquete({
      id_generar: "",
      id_logistica: "",
      id_local: "",
      factura: "",
      descripcion: "",
      cantidad: "",
      valorTotal: "",
      direccion: "",
      altura: "",
      piso: "",
      departamento: "",
      comuna: "",
      barrio: "",
      localidad: "",
      cp: "",
      telefono: "",
      nombreContacto: "",
      mailContacto: "",
      observaciones: "",
      domicilioTipo: "",
      horarioEntrega: "",
      hashCode: "",
      hashCtrl: "",
      pin: "",
      id_chofer: "",
      estado: "",
      fechacarga: "",
      fechaentrega: "",
      fechayhora: "",
    });
    setShowNuevoTienda(true);
  };

  const handleSaveTienda = () => {
    console.log("Datos de nueva tienda:", NuevoPaquete); // Imprime los datos de la nueva tienda por consola
    // Realiza una solicitud POST al servidor PHP para guardar la nueva tienda
    fetch("https://shipmaster.com.ar/backoffice/php/savetienda.php", {
      method: "POST",
      body: JSON.stringify(NuevoPaquete),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          // Si la solicitud fue exitosa, cierra el formulario de nueva tienda
          closeNTForm();
        } else {
          // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
          console.error("Error al agregar nueva tienda");
        }
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud:", error);
      });
  };

  const handleCancelNT = () => {
    // Simplemente cierra el formulario de nueva tienda sin hacer ningún cambio
    closeNTForm();
  };

  const closeNTForm = () => {
    setShowNuevoTienda(false);
  };

  const handleSaveEdit = (index) => {
    // Realiza una solicitud POST al servidor PHP para actualizar el usuario
    // fetch('https://shipmaster.com.ar/backoffice/php/listartiendaseditarusuario.php', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(usuariosTienda[index]), // Envía los datos del usuario editado al servidor
    // })
    //     .then(response => {
    //         if (response.ok) {
    //             // Si la solicitud fue exitosa, cancela el modo de edición
    //             setEditMode({ ...editMode, [index]: false });
    //             // Actualiza la lista de usuarios haciendo una nueva solicitud GET
    //             fetchUsuariosTienda(editTienda.id_tienda); // Llama a la función fetchUsuariosTienda para obtener los usuarios actualizados
    //         } else {
    //             // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
    //             console.error('Error al actualizar el usuario');
    //         }
    //     })
    //     .catch(error => {
    //         console.error('Error al enviar la solicitud:', error);
    //     });
  };

  const handleEdit = (index) => {
    // Cambiar al modo de edición para el usuario en la posición de índice
    setEditMode({ ...editMode, [index]: true });
  };

  const handleCancelEdit = (index) => {
    // Cancelar la edición y volver al modo de visualización para el usuario en la posición de índice
    setEditMode({ ...editMode, [index]: false });
  };

  return (
    <div className="containercursos">
      <h4>
        Universo Paquetes{" "}
        <i
          className="fas fa-graduation-cap"
          style={{ paddingLeft: "20px", color: "#0c5da1" }}
        ></i>
      </h4>
      <h4>
        user id:{userId} - id_logistica: {Idlogis} - id_Tienda: {Idtien}
      </h4>
      <button onClick={openNewTiendaForm}>Universo paqueteria</button>
      {/* Mostrar las tiendas en una tabla */}
      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>ID Generar</th>
              <th>ID Logística</th>
              <th>ID Local</th>
              <th>Factura</th>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th>Valor Total</th>
              <th>Dirección</th>
              <th>Altura</th>
              <th>Piso</th>
              <th>Departamento</th>
              <th>Comuna</th>
              <th>Barrio</th>
              <th>Localidad</th>
              <th>CP</th>
              <th>Teléfono</th>
              <th>Nombre Contacto</th>
              <th>Email Contacto</th>
              <th>Observaciones</th>
              <th>Tipo Domicilio</th>
              <th>Horario Entrega</th>
              <th>Hash Code</th>
              <th>Hash Ctrl</th>
              <th>PIN</th>
              <th>ID Chofer</th>
              <th>Estado</th>
              <th>Fecha Carga</th>
              <th>Fecha Entrega</th>
              <th>Fecha y Hora</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {universo.map((paquete) => (
              <tr key={paquete.id_generar}>
                <td>{paquete.id_generar}</td>
                <td>{paquete.id_logistica}</td>
                <td>{paquete.id_local}</td>
                <td>{paquete.factura}</td>
                <td>{paquete.descripcion}</td>
                <td>{paquete.cantidad}</td>
                <td>{paquete.valorTotal}</td>
                <td>{paquete.direccion}</td>
                <td>{paquete.altura}</td>
                <td>{paquete.piso}</td>
                <td>{paquete.departamento}</td>
                <td>{paquete.comuna}</td>
                <td>{paquete.barrio}</td>
                <td>{paquete.localidad}</td>
                <td>{paquete.cp}</td>
                <td>{paquete.telefono}</td>
                <td>{paquete.nombreContacto}</td>
                <td>{paquete.mailContacto}</td>
                <td>{paquete.observaciones}</td>
                <td>{paquete.domicilioTipo}</td>
                <td>{paquete.horarioEntrega}</td>
                <td>{paquete.hashCode}</td>
                <td>{paquete.hashCtrl}</td>
                <td>{paquete.pin}</td>
                <td>{paquete.id_chofer}</td>
                <td>{paquete.estado}</td>
                <td>{paquete.fechacarga}</td>
                <td>{paquete.fechaentrega}</td>
                <td>{paquete.fechayhora}</td>
                <td>
                  {/* Mostrar el botón "Guardar" si está en modo de edición, de lo contrario, muestra "Editar" */}
                  {/* {editMode[index] ? ( */}
                  <button onClick={() => handleSaveEdit(paquete.id_generar)}>
                    Guardar
                  </button>
                  {/* ) : (*/}
                  <button onClick={() => handleEdit(paquete.id_generar)}>Editar</button>
                  {/*  )}*/}
                  {/* Mostrar el botón "Cancelar" si está en modo de edición, de lo contrario, muestra "Eliminar" */}
                  {/* {editMode[index] && (*/}
                  <button onClick={() => handleCancelEdit(paquete.id_generar)}>
                    Cancelar
                  </button>
                  {/* )}*/}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Mostrar el formulario de ALTA de tienda si showNewTiendaForm es true */}
      {showNewTiendaForm && (
        <div className="modal-container">
          <div className="modal-content">
            <h3>Nuevo Paquete</h3>
            <form className="form-grid">
              <label htmlFor="id_generar">ID Generar:</label>
              <input
                type="text"
                id="id_generar"
                name="id_generar"
                value={NuevoPaquete.id_generar}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    id_generar: e.target.value,
                  })
                }
              />

              <label htmlFor="id_logistica">ID Logística:</label>
              <input
                type="text"
                id="id_logistica"
                name="id_logistica"
                value={NuevoPaquete.id_logistica}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    id_logistica: e.target.value,
                  })
                }
              />

              <label htmlFor="id_local">ID Local:</label>
              <input
                type="text"
                id="id_local"
                name="id_local"
                value={NuevoPaquete.id_local}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    id_local: e.target.value,
                  })
                }
              />

              <label htmlFor="factura">Factura:</label>
              <input
                type="text"
                id="factura"
                name="factura"
                value={NuevoPaquete.factura}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    factura: e.target.value,
                  })
                }
              />

              <label htmlFor="descripcion">Descripción:</label>
              <input
                type="text"
                id="descripcion"
                name="descripcion"
                value={NuevoPaquete.descripcion}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    descripcion: e.target.value,
                  })
                }
              />

              <label htmlFor="cantidad">Cantidad:</label>
              <input
                type="text"
                id="cantidad"
                name="cantidad"
                value={NuevoPaquete.cantidad}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    cantidad: e.target.value,
                  })
                }
              />

              <label htmlFor="valorTotal">Valor Total:</label>
              <input
                type="text"
                id="valorTotal"
                name="valorTotal"
                value={NuevoPaquete.valorTotal}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    valorTotal: e.target.value,
                  })
                }
              />

              <label htmlFor="direccion">Dirección:</label>
              <input
                type="text"
                id="direccion"
                name="direccion"
                value={NuevoPaquete.direccion}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    direccion: e.target.value,
                  })
                }
              />

              <label htmlFor="altura">Altura:</label>
              <input
                type="text"
                id="altura"
                name="altura"
                value={NuevoPaquete.altura}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    altura: e.target.value,
                  })
                }
              />

              <label htmlFor="piso">Piso:</label>
              <input
                type="text"
                id="piso"
                name="piso"
                value={NuevoPaquete.piso}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    piso: e.target.value,
                  })
                }
              />

              <label htmlFor="departamento">Departamento:</label>
              <input
                type="text"
                id="departamento"
                name="departamento"
                value={NuevoPaquete.departamento}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    departamento: e.target.value,
                  })
                }
              />

              <label htmlFor="comuna">Comuna:</label>
              <input
                type="text"
                id="comuna"
                name="comuna"
                value={NuevoPaquete.comuna}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    comuna: e.target.value,
                  })
                }
              />

              <label htmlFor="barrio">Barrio:</label>
              <input
                type="text"
                id="barrio"
                name="barrio"
                value={NuevoPaquete.barrio}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    barrio: e.target.value,
                  })
                }
              />

              <label htmlFor="localidad">Localidad:</label>
              <input
                type="text"
                id="localidad"
                name="localidad"
                value={NuevoPaquete.localidad}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    localidad: e.target.value,
                  })
                }
              />

              <label htmlFor="cp">Código Postal:</label>
              <input
                type="text"
                id="cp"
                name="cp"
                value={NuevoPaquete.cp}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    cp: e.target.value,
                  })
                }
              />

              <label htmlFor="telefono">Teléfono:</label>
              <input
                type="text"
                id="telefono"
                name="telefono"
                value={NuevoPaquete.telefono}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    telefono: e.target.value,
                  })
                }
              />

              <label htmlFor="nombreContacto">Nombre Contacto:</label>
              <input
                type="text"
                id="nombreContacto"
                name="nombreContacto"
                value={NuevoPaquete.nombreContacto}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    nombreContacto: e.target.value,
                  })
                }
              />

              <label htmlFor="mailContacto">Mail Contacto:</label>
              <input
                type="text"
                id="mailContacto"
                name="mailContacto"
                value={NuevoPaquete.mailContacto}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    mailContacto: e.target.value,
                  })
                }
              />

              <label htmlFor="observaciones">Observaciones:</label>
              <input
                type="text"
                id="observaciones"
                name="observaciones"
                value={NuevoPaquete.observaciones}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    observaciones: e.target.value,
                  })
                }
              />

              <label htmlFor="domicilioTipo">Tipo de Domicilio:</label>
              <input
                type="text"
                id="domicilioTipo"
                name="domicilioTipo"
                value={NuevoPaquete.domicilioTipo}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    domicilioTipo: e.target.value,
                  })
                }
              />

              <label htmlFor="horarioEntrega">Horario de Entrega:</label>
              <input
                type="text"
                id="horarioEntrega"
                name="horarioEntrega"
                value={NuevoPaquete.horarioEntrega}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    horarioEntrega: e.target.value,
                  })
                }
              />

              <label htmlFor="hashCode">Hash Code:</label>
              <input
                type="text"
                id="hashCode"
                name="hashCode"
                value={NuevoPaquete.hashCode}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    hashCode: e.target.value,
                  })
                }
              />

              <label htmlFor="hashCtrl">Hash Ctrl:</label>
              <input
                type="text"
                id="hashCtrl"
                name="hashCtrl"
                value={NuevoPaquete.hashCtrl}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    hashCtrl: e.target.value,
                  })
                }
              />

              <label htmlFor="pin">PIN:</label>
              <input
                type="text"
                id="pin"
                name="pin"
                value={NuevoPaquete.pin}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    pin: e.target.value,
                  })
                }
              />

              <label htmlFor="id_chofer">ID Chofer:</label>
              <input
                type="text"
                id="id_chofer"
                name="id_chofer"
                value={NuevoPaquete.id_chofer}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    id_chofer: e.target.value,
                  })
                }
              />

              <label htmlFor="estado">Estado:</label>
              <input
                type="text"
                id="estado"
                name="estado"
                value={NuevoPaquete.estado}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    estado: e.target.value,
                  })
                }
              />

              <label htmlFor="fechacarga">Fecha de Carga:</label>
              <input
                type="text"
                id="fechacarga"
                name="fechacarga"
                value={NuevoPaquete.fechacarga}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    fechacarga: e.target.value,
                  })
                }
              />

              <label htmlFor="fechaentrega">Fecha de Entrega:</label>
              <input
                type="text"
                id="fechaentrega"
                name="fechaentrega"
                value={NuevoPaquete.fechaentrega}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    fechaentrega: e.target.value,
                  })
                }
              />

              <label htmlFor="fechayhora">Fecha y Hora:</label>
              <input
                type="text"
                id="fechayhora"
                name="fechayhora"
                value={NuevoPaquete.fechayhora}
                onChange={(e) =>
                  setNuevoPaquete({
                    ...NuevoPaquete,
                    fechayhora: e.target.value,
                  })
                }
              />
            </form>
            <button onClick={handleSaveTienda}>Guardar</button>
            <button onClick={handleCancelNT}>Cancelar</button>
            <p style={{ marginBottom: "30px" }} />
          </div>
        </div>
      )}
      {/* <div className="footer">
        <p>shipmaster - Tecnología en shipmaster</p>
      </div> */}
    </div>
  );
};

export default UniversoPaquetes;
