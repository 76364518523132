import React, { useEffect, useState } from "react";
import EnCamino from "./camino";
import "./dashboard.css";
import Generar from "./generar";
import LogisticaDash from "./logisticadash";
//import NuevaTienda from "./nuevatienda";
import UniversoPaquetes from "./universopaquetes";
import AdministrarChoferes from "./administrarchoferes";
import AdministrarLogisticas from "./administrarlogisticas";
import AdministrarTiendas from "./administrartiendas";
import AdministrarUsuarios from "./administrarusuarios";
// import Registros from "./registros";
// import Vivero from "./vivero";
// import Consulta from "./consulta";
// import Descarga from "./descarga";
// import Usuario from "./usuario";
// import Blog from "./blog";
// import BlogPost from "./BlogPost";
// import Cursos from "./cursos";

//import Configuracion from "./configuracion";
import Salir from "./salir";
//import { useNavigate } from "react-router-dom";//para salir 


const Adminppal = () => {
  const [selectedItem, setSelectedItem] = useState("logistica");
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  //const navigate = useNavigate();//para sarir
  //const [desde, setDesde] = useState("");
  //const [hasta, setHasta] = useState("");
  const [userId, setUserId] = useState("userId");
  const [Idlogis, setIdlogis] = useState("Idlogis");
  const [Idtien, setIdtien] = useState("Idtien");

  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    console.log("userId de ppal:" + userId)
    setIdlogis(localStorage.getItem("id_logis"));
    console.log("Idlogis de ppal:" + Idlogis)
    setIdtien(localStorage.getItem("id_tien"));
    console.log("Idtien de ppal:" + Idtien)

    const fecha = new Date();
    const anio = fecha.getFullYear(); // obtiene el año (por ejemplo, 2023)
    const mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // obtiene el mes con dos dígitos (por ejemplo, "04")
    const dia = ("0" + fecha.getDate()).slice(-2); // obtiene el día con dos dígitos (por ejemplo, "26")
    const fechaHasta = `${anio}-${mes}-${dia}`; // combina los componentes de fecha en una cadena con el formato "aaaa/mm/dd"
    console.log("fechafotmateada HASTA: " + fechaHasta); // "2023/04/26"


    const diasAtras = 7; // cuántos días atrás quieres obtener la fecha
    const fechaMenor = new Date(
      fecha.getTime() - diasAtras * 24 * 60 * 60 * 1000
    ); // resta los días a la fecha actual

    const anioD = fechaMenor.getFullYear(); // obtiene el año (por ejemplo, 2023)
    const mesD = ("0" + (fechaMenor.getMonth() + 1)).slice(-2); // obtiene el mes con dos dígitos (por ejemplo, "04")
    const diaD = ("0" + fechaMenor.getDate()).slice(-2); // obtiene el día con dos dígitos (por ejemplo, "23")

    const fechaDesde = `${anioD}-${mesD}-${diaD}`; // combina los componentes de fecha en una cadena con el formato "aaaa/mm/dd"
    console.log("fechafotmateada desde: " + fechaDesde); // "2023/04/23"


    localStorage.setItem("fechaDesde", fechaDesde); //datauser
    localStorage.setItem("fechaHasta", fechaHasta); //data mac user

    console.log("FECHA DESDE " + fechaDesde);
    console.log("fecha hasta" + fechaHasta);
  }, [userId, Idlogis, Idtien]);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === "salir") {
      window.location.href = "https://www.shipmaster.com.ar";
    } else {
      setSelectedItem(item);
    }
  };

  const menuItems = [
    { id: "logisticadash", label: "Dashboard", icon: "fas fa-chart-line", color: "#0c5da1" },
    { id: "generar", label: "Nuevo Envio", icon: "fas fa-chart-line", color: "#0c5da1" },
    { id: "camino", label: "En Camino", icon: "fas fa-chart-bar", color: "#b87f05" },
    { id: "viajando", label: "Entregados", icon: "fas fa-database", color: "#6b6b6b" },
    { id: "historico", label: "Historico de envios", icon: "fas fa-leaf", color: "green" },
    //{ id: "nuevatienda", label: "Nueva Tienda",icon:"fas fa-question-circle", color:"#43099a" },
    { id: "universopaquetes", label: "Universo Paquetes",icon:"fas fa-question-circle", color:"#43099a" },
    { id: "admtiendas", label: "Administrar Tiendas",icon:"fas fa-question-circle", color:"#43099a" },
    { id: "admchoferes", label: "Administrar Choferes",icon:"fas fa-question-circle", color:"#43099a" },
    { id: "admusuarios", label: "Administrar Usuarios",icon:"fas fa-question-circle", color:"#43099a" },
    { id: "admlogisticas", label: "Administrar Logisticas",icon:"fas fa-question-circle", color:"#43099a" },
    // { id: "descarga", label: "Descargas" ,icon:"fas fa-download", color:"#0c5da1"},
    // { id: "usuario", label: "Usuario" ,icon:"fas fa-user", color:"#839192"},
    // { id: "blog", label: "Blog" ,icon:"fas fa-Blog", color:"#CF9951"},
    //{ id: "cursos", label: "Cursos" ,icon:"fas fa-graduation-cap", color:"darkblue"},
    // { id: "configuracion", label: "Configuracion" }, 
    { id: "salir", label: "Salir", icon: "fas fa-sign-out-alt", color: "red" },
  ];

  return (
    <div className="dashboard">
      <div className={`sidebar ${isSidebarVisible ? "expanded" : ""}`}>
        <ul>
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.id}
              onClick={() => handleItemClick(menuItem.id)}
              className={selectedItem === menuItem.id ? "active" : ""}
            >
              <i class={menuItem.icon} style={{ color: menuItem.color }}></i> {menuItem.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="contentDash">
        <div className="toggle-container">
          <div
            className={`toggle-button ${isSidebarVisible ? "expanded" : ""}`}
            onClick={toggleSidebar}
          >
            <span className="arrow"></span>
          </div>
        </div>
        
        {/* Componentes del dashboard */}
        <div className={`content ${isSidebarVisible ? "expanded" : ""}`} >
          {/* Renderizar el componente correspondiente según la selección del menú */}
          {selectedItem === "logisticadash" && <LogisticaDash />}
          {selectedItem === "generar" && <Generar />}
          {selectedItem === "camino" && <EnCamino />}
          {/*{selectedItem === "nuevatienda" && <NuevaTienda />}*/}
          {selectedItem === "universopaquetes" && <UniversoPaquetes />}
          {selectedItem === "admtiendas" && <AdministrarTiendas />}
          {selectedItem === "admchoferes" && <AdministrarChoferes />}
          {selectedItem === "admusuarios" && <AdministrarUsuarios />}
          {selectedItem === "admlogisticas" && <AdministrarLogisticas />}
          {/*selectedItem === "consulta" && <Consulta />}
          {selectedItem === "descarga" && <Descarga />}
          {selectedItem === "usuario" && <Usuario />}
          {selectedItem === "blog" && <Blog />}
          {selectedItem === "/:id" && <BlogPost />}
          {selectedItem === "cursos" && <Cursos />}
           {selectedItem === "configuracion" && <Configuracion />} */}
          {selectedItem === "salir" && <Salir />}

        </div>
      </div>
    </div>
  );
};

export default Adminppal;
